import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Freetown Road Project",
  pageDescription:
    "See how the owner of a Caribbean restaurant stays on top of every aspect of his establishment with SpotOn technology.",
  title: "Coordinating a Caribbean restaurant",
  subTitle: `How Freetown Road Project effortlessly stays on top of business.`,
  businessName: "Freetown Road Project",
  industry: "Restaurants & hospitality, casual dining",
  location: "Jersey City, New Jersey",
  products: [spotonProducts.RESTAURANT],
  description:
    "After winning the popular TV show Chopped, Claude Lewis set out to showcase the flavors of Antigua and Barbuda in his new Caribbean restaurant—Freetown Road Project. And with SpotOn, he found a restaurant POS where he could maintain a bird’s-eye view of his entire operation.",
  quoteData: {
    imgName: "freetown-road-project.png",
    quote: `“SpotOn reporting and the manager app keep me totally in sync when I’m not at the restaurant. From anywhere, I can see the number of open tables, who is doing what, and even see payroll and labor costs with a breakdown of how much we’re making. It's so easy to know what’s going on hourly and daily when I’m not at the restaurant.”`,
    personName: "Claude Lewis",
    personTitle: "Owner, Freetown Road Project",
  },
  goal: `Lewis needed a modern cloud-based platform that would streamline communications between the front-of-house and back-of-house and give him access to his restaurant information from anywhere.`,
  solution: `With SpotOn Restaurant, orders go directly to the kitchen, prices and menu items can be adjusted on the fly, and Lewis can see everything right from his phone. On top of that, he also enjoys 24/7/365 customer support.`,
  results: {
    title: `The results`,
    stats: [
      { title: `3 days`, description: `faster access to funds` },
      {
        title: `45%`,
        description: `increase in kitchen productivity`,
      },
      { title: `Improved`, description: `customer service and fewer errors` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Selling tacos, turning tables",
    imageName: "la-chiva-loka.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-14",
    href: "/case-studies/la-chiva-loka",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Attracting more fast-casual vegan customers",
    imageName: "majani-soulful-vegan-cuisine.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-07",
    href: "/case-studies/majani-soulful-vegan-cuisine",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
  {
    title: "Boosting traffic to a BBQ business",
    imageName: "chicago-culinary-kitchen.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-02",
    href: "/case-studies/chicago-culinary-kitchen",
    goals: [
      resourceGoals.REACH_NEW_CUSTOMERS,
      resourceGoals.BUILD_CUSTOMER_LOYALTY,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
];
